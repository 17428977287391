import Image from 'next/image';
import dynamic from 'next/dynamic';
import styles from '../../Styles/col.module.css'
const CountUp = dynamic(() => import('react-countup'), {
    ssr: false,
});

const DeductTaxes = () => {
    return (
        <div className='h-fit'>
            <section id="section_002" className="w-full align-center pt-6 bg-gray-100">
                <div className="pw z-10">
                    <div className="flex justify-center w-full xs:mb-12 mt-12 md:mb-16">
                        <h2 className="mt-6 mb-4 font-fredoka text-4.5xl text-center">
                            Deduce impuestos y ahorra dinero
                        </h2>
                    </div>
                    <div className="w-full flex md:flex-row flex-col">
                        <div className="md:w-1/3 w-full xs:mb-10 md:mb-0">
                            <h3 className="float-left w-full text-xl font-fredoka xs:mt-0 md:-mt-2">
                                Impuestos deducidos con FIXAT®
                            </h3>
                            <span className="float-left w-full text-4.5xl font-fredoka tracking-wider mt-6 mb-8">$
                                <CountUp
                                    start={0}
                                    end={2234050}
                                    duration={10}
                                    useEasing={true}
                                    useGrouping={true}
                                    separator=","
                                    decimals={0}
                                    decimal=","
                                />
                            </span>
                            <div className='relative w-full h-[222px] mt-28'>
                                <Image loading="lazy"
                                    src="https://cdn.fixat.mx/assets/home/img/impuestos-deducidos-con-fixat.png"
                                    id="img_008"
                                    alt="impuestos deducidos con Fixat"
                                    title="impuestos deducidos con Fixat"
                                    layout='fill'
                                    objectFit='contain'
                                />
                            </div>
                        </div>
                        <div className="w-1/3 bottom-6 text-white xs:hidden md:block">
                            <div className="px-7">
                                <div className="w-full align-left">
                                    <Image loading="lazy"
                                        src="https://cdn.fixat.mx/assets/home/img/mas-personas-confian-en-fixat.png"
                                        alt="Cada vez son más las personas que confían en FIXAT®"
                                        title="Cada vez son más las personas que confían en FIXAT®"
                                        id="img_010"
                                        height="427px"
                                        width="404px"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3 w-full xs:mb-10 md:mb-0">
                            <h3 className="float-left w-full text-xl font-fredoka xs:mt-16 md:-mt-2">
                                Monto ahorrado de Multas
                            </h3>
                            <span className="float-left w-full text-4.5xl font-fredoka tracking-wider mt-6 mb-8">$
                                <CountUp
                                    start={0}
                                    end={1023387}
                                    duration={10}
                                    useEasing={true}
                                    useGrouping={true}
                                    separator=","
                                    decimal=","
                                />
                            </span>
                            <div className='relative w-full h-[222px] xs:mt-36 md:mt-28'>
                                <Image loading="lazy"
                                    src="https://cdn.fixat.mx/assets/home/img/monto-ahorrado-en-multas.png"
                                    id="img_009"
                                    alt="Monto ahorrado en multas"
                                    title="Monto ahorrado en multas"
                                    layout='fill'
                                    objectFit='contain'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <p className="pb-12 lg:pb-0 pt-6 text-2xl lg:text-white text-center max-w-2xl">
                            Cada vez son más las personas que confían en FIXAT® y que recuperan parte del dinero que pagaron en impuestos.
                        </p>
                    </div>
                </div>
                <span id="section_002_after" className={`${styles['col-12']} hidden lg:block`}>
                </span>
            </section>
        </div>
    );
}

export default DeductTaxes;
